
import { Component, Vue } from 'vue-property-decorator';
import { isEqual } from 'lodash';

@Component
export default class PendingChangesGuard<T> extends Vue {
  hasPendingChanges = false;
  value: T = null;
  initialValue: T = null;

  beforeRouteLeave(to, from, next) {
    if (!isEqual(this.value, this.initialValue)) {
      if (window.confirm(`${this.$t('pending-changes')}`)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
}
