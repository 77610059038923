
import VJsoneditor from 'v-jsoneditor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { extend, VlAccordion } from '@govflanders/vl-ui-vue-components';
import Ajv from 'ajv';

const ajv = new Ajv();

Vue.use(VJsoneditor);

@Component({
  components: { VJsoneditor },
})
export default class JsonEditor extends Vue {
  private accordionRef: VlAccordion;

  @Prop({
    type: Object,
    required: true,
  })
  public schema!: object;

  @Prop({
    type: Object,
    required: true,
  })
  public value: object;

  @Prop({
    type: Boolean,
    required: false,
  })
  public modOpenend: boolean;

  public get editorOptions() {
    return {
      schema: this.schema,
      mode: 'code',
      modes: ['tree', 'form', 'code'],
    };
  }

  public get currentValue() {
    return this.value;
  }

  public set currentValue(val: object) {
    this.$emit('input', val);
  }

  private mounted() {
    this.accordionRef = this.$refs.accordion as VlAccordion;

    if (this.modOpenend) {
      this.accordionRef?.accordionOpen();
    }

    extend('validJsonForSchema', {
      validate: async value => {
        if (this.schema) {
          const jsonValidator = ajv.compile(this.schema);

          const isValid = await jsonValidator(value);
          if (!isValid) {
            this.accordionRef?.accordionOpen();
          }

          return isValid;
        }

        return false;
      },
      message: () => this.$t('json.error-in-schema'),
    });
  }
}
