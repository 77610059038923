import Branding from './branding.json';
import Certificate from './certificate.json';
import Contact from './contact.json';
import Default from './default.json';
import GlobalFooter from './global-footer.json';
import GlobalHeaderExtensions from './global-header-extensions.json';
import GlobalHeader from './global-header.json';
import MBPApp from './mbp-app.json';

export default {
  Branding,
  Certificate,
  Contact,
  Default,
  GlobalFooter,
  GlobalHeader,
  GlobalHeaderExtensions,
  MBPApp,
};
