import Branding from './branding.json';
import Certificate from './certificate.json';
import Contact from './contact.json';
import Default from './default.json';
import GlobalFooter from './global-footer.json';
import GlobalHeader from './global-header.json';
import IpdcProduct from './ipdc-product.json';
import MBPApp from './mbp-app.json';

export default {
  Default,
  GlobalFooter,
  GlobalHeader,
  Certificate,
  Contact,
  IpdcProduct,
  MBPApp,
  Branding,
};
